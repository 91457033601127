<div class="row justify-content-center align-items-center p-0 m-0 d-flex">
  <div class="col-8  d-none d-sm-flex justify-content-center"
    style="height:100vh; ">
    <!--background-color: #E9F3F0!important;-->
    <ngu-carousel [hidden]="env['landingScreenBanner'] && (env['landingScreenBanner'] != 'undefined') ? false : true" style="display: flex; align-items: center;width: 100%;" [inputs]="carouselBanner"
      [dataSource]="caursolList">
      <ngu-item NguCarouselItem class="bannerStyle" *nguCarouselDef="let item; let j = index">
        <div class="row d-flex justify-content-center align-items-center" style="height:100vh">
          <h2 class="text-primary mat-headline-4 text-center my-3 w-100">{{item.name}}</h2>
          <p class="w-100 mb-3 text-center "><img [src]="item.image" style="height:50vh" alt=""></p>
          <div class="row align-items-center justify-content-center py-2 my-2">
            <div class="col-3 col-xs-6">
              <p class="m-0 p-0 text-center">
                <img style="width: 75px;" [src]="item.icon1" alt="No Img">
              </p>
              <h5 class="my-2 mat-headline-6 py-2 w-100 text-center">{{item.name1}}</h5>
            </div>
            <div class="col-3 col-xs-6">
              <p class="m-0 p-0 text-center">
                <img style="width: 75px;" [src]="item.icon2" alt="">
              </p>
              <h5 class="my-2 mat-headline-6 py-2 w-100 text-center">{{item.name2}}</h5>
            </div>
            <div class="col-3 col-xs-6">
              <p class="m-0 p-0 text-center">
                <img style="width: 75px;" [src]="item.icon3" alt="">
              </p>
              <h5 class="my-2 mat-headline-6 py-2 w-100 text-center">{{item.name3}}</h5>
            </div>
            <div class="col-3 col-xs-6">
              <p class="m-0 p-0 text-center">
                <img style="width: 75px;" [src]="item.icon4" alt="">
              </p>
              <h5 class="my-2 mat-headline-6 py-2 w-100 text-center">{{item.name4}}</h5>
            </div>
          </div>
          <h5 style="text-align:center;padding:30px;" class="w-100">Lorem Ipsum is simply dummy text of the printing
            and
            typesetting industry. Lorem Ipsum has been the industry's</h5>
        </div>
      </ngu-item>
      <button NguCarouselPrev class="svgicon leftRs" style="background:transparent"></button>
      <button NguCarouselNext class="svgicon rightRs" style="background:transparent"></button>
    </ngu-carousel>
    <ng-container *ngIf="env['landingScreenLogo'] && (env['landingScreenLogo'] != 'undefined') && (env['landingScreenLogo'] != 'false')">
      <img [src]="env['landingScreenLogo']">
    </ng-container>    
  </div>
  <div class="col-4  align-items-center justify-content-center login-bg "
    style="height:100vh;background-color: rgb(29, 36, 62) !important;">
    <div class="row  d-flex justify-content-center align-items-center p-0 py-4" style="height:100vh">
      <ng-container *ngIf="showUpdatePopup==0">
      <form [formGroup]="loginform" (ngSubmit)="formsubmit()" class="login-form col-12">
        <div class="row  d-flex justify-content-center align-items-center p-0 m-0">
          <h1 class="col-12 text-center">Login</h1>
          <div class="col-lg-9 col-md-6 col-sm-10 col-xs-10 mb-2">
            <div class="mb-2">
            <mat-form-field [floatLabel]="'always'" class="w-100" appearance="outline">
              <mat-label class="w-100">Employee ID</mat-label>
              <input autocomplete="off" matInput placeholder="eg:1001" formControlName="user_id">
              <mat-error *ngIf="loginform.controls['user_id'].hasError('required')">ID is required
              </mat-error>
            </mat-form-field>
          </div>
            <mat-form-field [floatLabel]="'always'" class="w-100" appearance="outline" *ngIf="action_type==1">
              <mat-label class="w-100">Password</mat-label>
              <input autocomplete="off" matInput placeholder="Password" formControlName="user_pwd" type="{{cs.showPassword ? 'text':'password'}}">
              <span color="warn" *ngIf="cs.showPassword==false" class="mdi mdi-eye-off password-eye" (click)="cs.toggleShowPassword()"></span>
              <span color="warn" *ngIf="cs.showPassword==true" class="mdi mdi-eye password-eye" (click)="cs.toggleHidePassword()"></span>
              <mat-error *ngIf="loginform.controls['user_pwd'].hasError('required')">Password is required
              </mat-error>
              <!-- <mat-error *ngIf="loginform.controls['user_pwd'].hasError('pattern')" class="mat-error">
                A. Password should be minimum 8 characters in length.
                B. Password should contain at least one uppercase and small case Alphabet,
                one special character and one number.
                </mat-error> -->
            </mat-form-field>
            <p class="text-center" class="w-100" style="color:red">{{form_validation}}</p>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 mt-2" *ngIf="action_type==1">
              <a fxflex color="primary" type="button" (click)="changeform(2)">
                Forgot Password?
              </a>
              <button fxflex  mat-raised-button color="primary" class="mat-elevation-z3"
                type="submit">
                SUBMIT
              </button>
            </div>
            
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 mt-2" *ngIf="action_type==2">
              <a  fxFlex color="primary" type="button" (click)="changeform(1)">
                Back to Login?
              </a>
              <button fxFlex mat-raised-button color="primary" class="mat-elevation-z3"
                type="submit">
                Reset Password
              </button>
            </div>
            <!--<div class="col-12 text-center pt-5">
              <span class="secondary-color">2022 &#9400; SMATOAPPS | Contact Email:
                <a href="#" style="color:#fff">support&#64;smatoapps.com</a>
              </span>
            </div> -->
            <div class="col-12 text-center pt-5">
              <span class="secondary-color">
                <ng-container *ngIf="env['organizationName'] && (env['organizationName'] != 'undefined') && (env['organizationName'] != 'false')">
                  {{env['currentYear']}} &#9400; {{env['organizationName']}}
                </ng-container>
                <ng-container *ngIf="env['organizationUrl'] && (env['organizationUrl'] != 'undefined') && (env['organizationUrl'] != 'false')">
                  | Contact Email: <a href="#" style="color:#fff">{{env['organizationUrl']}}</a>
                </ng-container>
              </span>
            </div>
          </div>
        </div>
      </form></ng-container>
  <ng-container *ngIf="showUpdatePopup==1">
  <app-update-pwd [update_msg]="updateMessg" [user_details]="userDetail" (formCloseEvent)="close($event)"></app-update-pwd>
  </ng-container>
    </div>
  </div>
</div>
